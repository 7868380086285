import { Environment } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments/sportsbook/lvbetlv/environment.base';

export const environment: Environment = {
  ...environmentBase,
  production: true,
  userApiUrl: 'https://user.lvbet.lv/',
  walletApiUrl: 'https://wallet.lvbet.lv/',
  paymentApiUrl: 'https://payment.lvbet.lv/',
  cmsApiUrl: {
    default: 'https://lvbet.lv/sports/',
    tv: 'https://terminal.lvbet.lv/',
    mobile: 'https://m-sports.lvbet.lv/'
  },
  cmsHeadlessApiUrl: 'https://cms.lvbet.lv/',
  shadowStylesUrl: {
    default: 'https://lvbet-static.com/shadow-css/lvbet-lv/sports/web/',
    'mobile-web': 'https://lvbet-static.com/shadow-css/lvbet-lv/sports/mobile/'
  }
};
