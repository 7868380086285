import { ProductType } from '@bcf-shared-settings/settings/enums';
import { environmentCommon } from 'configs/environments/environment.common';

const brandName: string = 'lvbetlv';
// eslint-disable-next-line @typescript-eslint/typedef
export const environmentBase = {
  ...environmentCommon,
  productType: ProductType.sportsbook,
  brandI18Name: brandName,
  brandCmsName: 'lvbetlv',
  brandName: 'Lv Bet',
  langs: ['en', 'lv', 'ru'],
  langsI18nMap: {
    en: 'en_US'
  },
  cookiesFromUrlMap: {
    btag: 'lvbetlv_btag'
  },
  cmsHeadlessEnabled: true
};
